<template>
  <nav class="lb-navigation-wrapper">
    <div class="lb-nav-group group" v-for="item in navigationMenu" :class="{'relative' : item.custom.dropdownType === 'dropdown'}" @mouseleave="item.isShowMenu=false">
      <NuxtLinkLocale class="lb-nav-item"  :to="getLocaleString(item.href, true)" @mouseover="item.isShowMenu=true" >
        {{ getLocaleString(item.name) }}
      </NuxtLinkLocale>

      <transition
        enter-active-class="transition duration-200 ease-out"
        enter-from-class="translate-y-1 opacity-0"
        enter-to-class="translate-y-0 opacity-100"
        leave-active-class="transition duration-150 ease-in"
        leave-from-class="translate-y-0 opacity-100"
        leave-to-class="translate-y-1 opacity-0"
      >
        <div
          class="absolute px-8 py-6 bg-white shadow-xl z-10 text-base overflow-y-auto scrollbar-thumb-gray-300 scrollbar-track-transparent scrollbar-thin custom-scrollbar"
          :class="[item.custom?.isFullWidth ? '' : 'max-w-4xl', item.custom.dropdownType === 'mega' ? 'dropdown-position' : 'left-0 w-auto']"
          v-if="item?.children.length > 0 && item?.isShowMenu"
        >
          <!-- Mega Dropdown | Etech için "flex-col" -->
          <div class="main-dropdown-wrapper" :class="item.custom?.customClass" v-if="item.custom.dropdownType === 'mega'">
            <!-- Ana Kategori -->
            <div
              class="py-1"
              v-for="mainCategory in item?.children"
              :key="mainCategory?.id"
            >
              <NuxtLinkLocale
                class="dropdown--line--1"
                :to="getLocaleString(mainCategory.href, true)"
                @click.prevent="item.isShowMenu=false"
              >
                {{ getLocaleString(mainCategory.name) }}
              </NuxtLinkLocale>
              
              <!-- Alt Kategori -->
              <div class="max-w-sm" v-if="mainCategory?.children.length > 0">
                <ul role="list" class="dropdown--line--2--wrapper">
                  <li
                    v-for="subCategory in mainCategory?.children"
                    :key="`mcc-${subCategory?.id}`"
                  >
                    <NuxtLinkLocale
                      class="dropdown--line--2"
                      :to="getLocaleString(subCategory?.href, true)"
                      @click.prevent="item.isShowMenu=false"
                    >
                      <span
                          class="dropdown--line--2--icon"
                          aria-hidden="true"
                          v-if="subCategory?.custom?.icon"
                          v-html="subCategory?.custom?.icon"
                      ></span>
                      {{ getLocaleString(subCategory?.name) }}
                    </NuxtLinkLocale>
                  </li>
                </ul>
              </div>

            </div>

            <!-- Menü Görseli -->
            <div class="flex justify-end" v-if="item?.custom?.image">
              <NuxtImg
                  :src="getImage(item.custom.image)"
                  width="420"
                  height="250"
                  :alt="getLocaleString(item.name)"
              />
            </div>
          </div>

          <div class="space-y-2" v-else-if="item.custom.dropdownType === 'dropdown'" :class="item.custom?.customClass">
            <template
                v-for="mainCategory in item?.children"
                :key="mainCategory?.id"
            >
              <NuxtLinkLocale
                  class="flex items-center gap-5 cursor-pointer whitespace-nowrap"
                  :to="getLocaleString(mainCategory.href, true)"
                  @click.prevent="item.isShowMenu=false"
              >
                <span
                    class="h-6 w-6 flex items-center justify-center"
                    aria-hidden="true"
                    v-if="mainCategory?.custom?.icon"
                    v-html="mainCategory?.custom?.icon"
                ></span>
                <span class="text-base text-primary hover:text-secondary">
                  {{ getLocaleString(mainCategory.name) }}
                </span>
              </NuxtLinkLocale>
            </template>

          </div>
        </div>
      </transition>
    </div>
  </nav>
</template>

<script setup>
const storeConfig = useStoreConfig()
const { navigationMenu, featuredMenu } = storeToRefs(storeConfig)
const activeMenu = useState("activeMenu", () => []);
</script>

<style lang="css">
.main-dropdown-wrapper {
  @apply px-4 py-6 sm:gap-x-4 flex flex-wrap max-h-[500px];
}

.dropdown-position {
  @apply left-1/2 w-full -translate-x-1/2 max-h-screen;
}

</style>